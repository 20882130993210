<template lang="pug">
include ../../../../configs/template
div.row.text-left
  div.col-sm-12.col-md-6
    +data-info('typeDoc', 'educationTypeDocumentByID(sailorDocument.type_document)[labelName]')
  div(v-if="sailorDocument.type_document === 1").col-sm-12.col-md-6
    +data-info('educationExtent', 'extentByID(sailorDocument.extent)[labelName]')
  div(v-if="sailorDocument.other_name_nz_ukr").col-sm-12
    +data-info('nameInstitution', 'sailorDocument.other_name_nz_ukr')
  div(v-else).col-sm-12
    +data-info('nameInstitution', 'institutionByID(sailorDocument.name_nz)[labelName]')
  div.col-sm-12
    +data-info('qualification', 'qualificationLevelByID(sailorDocument.qualification)[labelName]')
  div(v-if="sailorDocument.type_document === 1").col-sm-12
    +data-info('specialty', 'educationProfessionByID(sailorDocument.speciality)[labelName]')
  div(v-else-if="sailorDocument.type_document === 2").col-sm-12
    +data-info('profession', 'educationProfessionByID(sailorDocument.speciality)[labelName]')
  div(v-if="sailorDocument.type_document === 1").col-sm-12
    +data-info('specialization', 'specializationByID(sailorDocument.specialization)[labelName]')
  div.col-sm-12
    +data-info('notes', 'sailorDocument.special_notes')
</template>

<script>
import { getDateFormat, getStatus } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      getDateFormat,
      getStatus,
      checkAccess,
      rejectStatusId: 13,
      isOriginal: false,
      sailorOriginalDocument: undefined
    }
  },
  computed: {
    ...mapGetters(['educationTypeDocumentByID', 'extentByID', 'institutionByID', 'educationProfessionByID',
      'specializationByID', 'qualificationLevelByID', 'statusById', 'rejectionReasonByID']),
    ...mapState({
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    verificationRefusalReason () {
      return this.comments.find(item => item.additional_info.cancel_reason)
    },
    verificationRefusalReasonName () {
      return this.rejectionReasonByID(this.verificationRefusalReason.additional_info.reason)[this.labelName]
    }
  },
  methods: {
    ...mapActions(['getDocumentNostrificationById']),
    async getOriginalDocument () {
      if (!this.sailorOriginalDocument) {
        this.sailorOriginalDocument = await this.getDocumentNostrificationById({ documentId: this.sailorDocument.nostrification_document })
      }
    }
  }
}
</script>
